.image-gallery {
    width: 100%;
    height: auto;
}

.image-gallery-slide img {
    width: 100%;
    height: 250px;
    overflow: hidden;
    object-position: center center;
}

.image-gallery-slide .image-gallery-image {
    object-fit: cover !important;
}

.fullscreen .image-gallery-slide img {
    height: 100vh;
}

.css-1aquho2-MuiTabs-indicator {
    background-color: white !important;
}

.MuiTab-textColorPrimary {
    color: white !important;
}

.detailTabbar {
    background-color: #1F3060;
}

.detailType {
    color: #6B9986;
    text-transform: uppercase;
    font-weight: 600;
}