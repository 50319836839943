.mapbox-container {
  height: 100vh;
  background-color: red;
}

.MuiSwitch-switchBase {
  color: white !important;
}

.MuiSwitch-track {
  background-color: #1F3060 !important;
  opacity: 0.5 !important;
}

.Mui-checked+.MuiSwitch-track {
  opacity: 0.9 !important;
}